<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on }">
        <slot :on="on" name="button"
          ><v-btn x-small text v-on="on">
            <i class="caption red--text text--darken-1">
              Удалить аккаунт
            </i>
          </v-btn></slot
        >
      </template>

      <v-card>
        <v-card-actions class="text-center text-h5" :class="{ 'text-h6': $vuetify.breakpoint.xs }"
          ><v-spacer />Вы абсолютно уверены?<v-spacer />
          <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn></v-card-actions
        >
        <v-divider class="mb-5" />
        <slot name="text">
          <v-card-text class="text-center red--text">
            Это действие не может быть отменено. <br />
            Это приведет к безвозвратному удалению аккаунта {{ user.email }} и всех данных,
            связанных с ним. <br />Все покупки будут аннулированы
          </v-card-text>
          <v-card-text class="text-center">
            <span v-if="user.providerData && user.providerData[0].providerId === 'password'"
              >Пожалуйста введите пароль для подтверждения</span
            >
            <span v-else
              >Пожалуйста введите <b>{{ user.email }}</b> для подтверждения</span
            >
            <v-form ref="form" v-model="validForm" validation>
              <v-text-field v-model="password" type="text" outlined dense :rules="passwordRules" />
            </v-form>
          </v-card-text>
        </slot>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="!valid"
            :x-small="$vuetify.breakpoint.xs"
            :loading="loading"
            @click="onDelete"
            ><slot name="buttonName">Я понимаю последствия, удалить аккаунт</slot></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Attention',
  props: {
    origin: {
      type: String,
      required: false,
      default: ''
    }
  },
  data: () => ({
    dialog: false,
    validForm: false,
    password: '',
    passwordRules: [
      v => !!v || 'Is required',
      v => (v && v.length >= 3) || 'Must be equal or more than 3 characters'
    ]
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      loading: state => state.shared.loading
    }),
    valid() {
      if (this.origin === 'deleteAccount') {
        if (this.user.providerData && this.user.providerData[0].providerId === 'password') {
          return this.validForm
        } else {
          return this.password === this.user.email
        }
      } else {
        return true
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.password = ''
    },
    onDelete() {
      this.$emit('deleteItem', this.password)
      this.dialog = false
      this.password = ''
    }
  }
}
</script>

<style scoped />
